const errorHandlerMixin = {
  showError(_vm, message) {
    _vm.$snotify.error(message || "Se ha producido un error", "", {
      timeout: 2000,
      showProgressBar: false,
    });
  },
  errorHandler: (_vm, statusCode) => {
    if (statusCode == 500) {
      _vm.$snotify.error("Error en el servidor", "", {
        timeout: 2000,
        showProgressBar: false,
      });
    }

    if (statusCode == 401) {
      _vm.$snotify.error("No esta autorizado para acceder a esta sección", "", {
        timeout: 2000,
        showProgressBar: false,
      });
    }

    if (statusCode == 404) {
      _vm.$snotify.error("No se encontro el recurso", "", {
        timeout: 2000,
        showProgressBar: false,
      });
    }
  },
};

export default errorHandlerMixin;

const fairs = {
  namespaced: true,
  state: {
    fairs: [],
    fair: null,
  },
  getters: {
    fair: (state) => state.fair,
    fairByIndex: (state) => (index) => state.fairs[index],
    fairs: (state) => state.fairs,
    total: (state) => state.fairs.length,
    slug: (state) => state.fair?.slug,
  },
  mutations: {
    setFairs(state, fairs) {
      state.fairs = fairs;
    },
    setFair(state, fair) {
      state.fair = fair;
    },
  },
  actions: {
    setFairByIndex({ dispatch }, index) {
      dispatch("getFair", index);
    },
    async getFair({ state, commit }, index) {
      let fair = { ...state.fairs[index] };

      try {
        let response = await this._vm.$axiosApiMobile.get(
          `/fairs/${fair.id}/withModules`
        );

        commit("setFair", response.data);
      } catch {
        commit("setFair", fair);
      }
    },
    async getFairs({ commit }) {
      try {
        let response = await this._vm.$axiosApiMobile.get("/fairs");
        commit("setFairs", response.data);
      } catch (e) {
        //
      }
    },
    async getModulesFair({ state }) {
      let fair = { ...state.fair, modules: [] };

      try {
        let response = await this._vm.$axiosApiMobile.get(
          `/appModules?fairId=${state.fair.id}`
        );

        fair.modules = response.data;
        state.fair = fair;
      } catch (e) {
        //
      }
    },
  },
};

export default fairs;

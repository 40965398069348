const auth = {
  namespaced: true,
  state: {
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
  },
  getters: {
    userInfo: (state) => state.userInfo,
    authenticated: (state) => Boolean(state.userInfo),
  },
  mutations: {
    logout(state) {
      state.userInfo = null;
      localStorage.removeItem("userInfo");
    },

    setUserInfo(state, userInfo) {
      state.userInfo = JSON.stringify(userInfo);
      localStorage.setItem("userInfo", state.userInfo);
      state.userInfo = userInfo;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      await this._vm.$axiosFiseApi
        .post("/login-mobile", credentials)
        .then((res) => {
          return commit("setUserInfo", res.data);
        });
    },

    async logout({ commit, state }) {
      await this._vm.$axiosFiseApi.post("/logout", {
        token: state.userInfo.token,
      });
      commit("logout");
    },

    async changePassword({ commit }) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo.user.reset_password = 0;
      return commit("setUserInfo", userInfo);
    },
  },
};

export default auth;

<template>
  <v-bottom-navigation app>
    <v-btn @click="redirectToHome">
      <span>Ferias</span>
      <v-icon>mdi-home-outline</v-icon>
    </v-btn>
    <v-btn v-if="!this.isGuest" @click="openDialog = true">
      <span>Cerrar Sesión</span>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
    <dialog-confirm-logout
      :open="openDialog"
      @confirm-logout="confirmLogout"
      @cancel-logout="openDialog = false"
    ></dialog-confirm-logout>
  </v-bottom-navigation>
</template>

<script>
import sessionMixin from "@/mixins/sessionMixin";
import DialogConfirmLogout from "@/components/auth/DialogConfirmLogout";

export default {
  name: "BottomNav",
  data() {
    return {
      openDialog: false,
    };
  },
  components: {
    DialogConfirmLogout,
  },
  mixins: [sessionMixin],
  computed: {
    isGuest() {
      return !this.isAuthenticated;
    },
    visitorsBySync() {
      return this.$store.getters["visitors/visitorsBySync"] ? true : false;
    },
  },
  methods: {
    signOut() {
      this.openDialog = false;
      this.logout()
        .then(() => this.$router.go("/login"))
        .catch((e) => this.showError(e.response.data.error));
    },
    confirmLogout() {
      this.openDialog = true;
      this.signOut();
    },
    redirectToHome() {
      location.href = "/";
    },
  },
};
</script>

const plans = {
  namespaced: true,
  state: {
    plan: {},
  },
  getters: {
    plan: (state) => state.plan,
  },
  mutations: {
    setPlan(state, plan) {
      state.plan = plan;
    },
  },
};

export default plans;

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

export default {
  install(Vue) {
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);

    Object.keys(rules).forEach((rule) => {
      extend(rule, rules[rule]);
    });

    extend("verifyPassword", {
      validate: (value) => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
        return strongRegex.test(value);
      },
      message: () =>
        "La contraseña debe contener al menos: letras mayúsculas, letras minúsculas y dígitos",
    });

    localize("es", es);
  },
};

import { mapGetters, mapActions } from "vuex";

const sessionMixin = {
  computed: {
    ...mapGetters({
      getUserInfo: "auth/userInfo",
      isAuthenticated: "auth/authenticated",
    }),
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      logout: "auth/logout",
      changePassword: "auth/changePassword",
    }),
  },
};

export default sessionMixin;

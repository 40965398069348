<template>
  <div>
    <alert-offline></alert-offline>
    <vue-snotify />
    <component :is="layout">
      <router-view :layout.sync="layout" />
    </component>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    />
    <prompt-pwa :is-ios="ios"></prompt-pwa>
  </div>
</template>

<style>
.snotify,
.v-snack__content {
  font-family: "Roboto", sans-serif !important;
}
</style>

<script>
import eventHub from "@/eventhub";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const defaultLayout = "main-layout";

export default {
  name: "App",
  components: {
    Loading,
  },
  data() {
    return {
      ios: false,
      isLoading: false,
      fullPage: true,
      defaultLayout: "main-layout",
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
  methods: {
    isIos() {
      const isInStandaloneMode = () =>
        "standalone" in window.navigator && window.navigator.standalone;
      const types = [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ];
      if (
        !isInStandaloneMode() &&
        (types.includes(navigator.platform) ||
          (navigator.userAgent.includes("Mac") &&
            !navigator.platform.toLowerCase().includes("win")))
      ) {
        this.ios = true;
      } else {
        this.ios = false;
      }
    },
  },
  created() {
    this.isIos();

    eventHub.$on("showLoader", () => {
      this.isLoading = true;
    });

    eventHub.$on("hideLoader", () => {
      this.isLoading = false;
    });
  },
};
</script>

<template>
  <v-app-bar app class="elevation-0" dark dense>
    <div class="row justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <div
          v-if="link"
          class="d-flex nav-bar-title"
          @click="goTo(link.routeName)"
        >
          <v-icon v-if="link.showBackButton" color="secondary" class="mr-2"
            >mdi-chevron-left-circle-outline
          </v-icon>
          <v-toolbar-title>{{ link.title }}</v-toolbar-title>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>
<style scoped>
.nav-bar-title {
  cursor: pointer;
}
</style>
<script>
import sessionMixin from "@/mixins/sessionMixin";
import eventHub from "@/eventhub";

export default {
  name: "NavBar",
  mixins: [sessionMixin],
  computed: {
    link() {
      if (this.$route.name === "home") {
        return {
          routeName: "home",
          title: "Ferias " + process.env.VUE_APP_NAME,
          showBackButton: false,
        };
      } else if (this.$route.name === "fair") {
        return {
          routeName: "home",
          title: "Inicio",
          showBackButton: true,
        };
      } else if (this.$route.name === "general-programming") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name === "login") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name === "password-reset") {
        return {
          routeName: "login",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name === "interest-places") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name === "academic-event") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name === "expositors") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name === "conferences") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name === "visitors") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (["detail-visitor", "visitor"].includes(this.$route.name)) {
        return {
          routeName: "visitors",
          title: "Visitantes registrados",
          showBackButton: true,
        };
      } else if (this.$route.name === "plans-fair") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name === "plan-fair") {
        return {
          routeName: "plans-fair",
          title: "Planos Feria",
          showBackButton: true,
        };
      } else if (this.$route.name === "restaurants") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name == "general-information") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else if (this.$route.name == "offline") {
        return {
          routeName: "fair",
          title: this.fair.name,
          showBackButton: true,
        };
      } else {
        return null;
      }
    },
    fair() {
      return this.$store.getters["fairs/fair"];
    },
  },
  methods: {
    goTo(routeName) {
      if (this.$route.name !== routeName) {
        if (routeName == "home") {
          location.href = "/";
          return;
        }
        if (routeName == "visitors") {
          eventHub.$emit("showDialogVisitor");
          return;
        }
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

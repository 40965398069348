<template>
  <div>
    <v-snackbar color="red accent-2" top v-model="open">
      <v-icon class="mr-2">mdi-wifi-off</v-icon>
      Sin conexión con el servidor
    </v-snackbar>
  </div>
</template>

<script>
import { BASE_URL } from "@/constants/ServicesConstants";

export default {
  name: "alert-offline",
  mounted() {
    window.addEventListener("online", this.goOnline);
    window.addEventListener("offline", this.goOffline);
    this.startPolling();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.goOnline);
    window.removeEventListener("offline", this.goOffline);

    if (this.pollingId) {
      this.stopPolling();
    }
  },
  data() {
    return {
      open: false,
      online: false,
      pollingConfig: {
        url: BASE_URL + "appName",
        timeout: 5000,
        interval: 5000,
      },
      pollingId: null,
    };
  },
  methods: {
    goOnline() {
      if (!this.online) {
        this.open = false;
        this.online = true;
        this.$store.commit("offline/setIsOnline", true);
      }
    },
    goOffline() {
      if (this.online) {
        this.open = true;
        this.online = false;
        this.$store.commit("offline/setIsOnline", false);
      }
    },
    startPolling() {
      const { interval } = this.pollingConfig;
      this.pollingId = setInterval(() => {
        const { url, timeout } = this.pollingConfig;
        this.ping({ url, timeout }).then((online) => {
          online ? this.goOnline() : this.goOffline();
        });
      }, interval);
    },
    ping({ url, timeout }) {
      return new Promise((resolve) => {
        const isOnline = () => resolve(true);
        const isOffline = () => resolve(false);
        const xhr = new XMLHttpRequest();
        xhr.onerror = isOffline;
        xhr.ontimeout = isOffline;
        xhr.onreadystatechange = () => {
          if (xhr.readyState === xhr.HEADERS_RECEIVED) {
            if (xhr.status) {
              isOnline();
            } else {
              isOffline();
            }
          }
        };
        xhr.open("GET", url);
        xhr.timeout = timeout;
        xhr.send();
      });
    },
    stopPolling() {
      clearInterval(this.pollingId);
    },
  },
};
</script>

import store from "@/store";

const auth = (to, from, next) => {
  if (!store.getters["auth/authenticated"]) {
    return next({
      path: "/login",
    });
  }

  if (
    to.fullPath != "/password-reset-confirm" &&
    store.getters["auth/userInfo"].user.reset_password == 1
  ) {
    return next({
      path: "/password-reset-confirm",
    });
  }

  next();
};

const guest = (to, from, next) => {
  if (store.getters["auth/authenticated"]) {
    return next({
      path: from.path,
    });
  }

  next();
};

const online = (to, from, next) => {
  if (!store.getters["offline/isOnline"]) {
    store.commit("offline/setPrevRoute", to.path);
    next({ name: "offline" });
  }

  next();
};

export default { guest, auth, online };

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('nav-bar'),_c('v-main',{style:({
      'padding-bottom':
        _vm.$route.name === 'visitors'
          ? '184px'
          : _vm.$route.name === 'detail-visitor'
          ? '140px'
          : '56px',
    })},[_c('router-view')],1),_c('bottom-nav')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
const offline = {
  namespaced: true,
  state: {
    isOnline: false,
    prevRoute: null,
  },
  getters: {
    isOnline: (state) => state.isOnline,
    prevRoute: (state) => state.prevRoute,
  },
  mutations: {
    setIsOnline(state, isOnline) {
      state.isOnline = isOnline;
    },
    setPrevRoute(state, route) {
      state.prevRoute = route;
    },
  },
};

export default offline;

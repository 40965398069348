import axios from "axios";
import store from "@/store";

const axiosApiMobile = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosApiMobile.interceptors.request.use((config) => {
  const userInfo = store.getters["auth/userInfo"];
  const token = userInfo ? userInfo.token : "";
  config.headers.common = {
    [process.env.VUE_APP_API_TOKEN_HEADER]:
      `${process.env.VUE_APP_API_TOKEN_PREFIX}` + " " + token,
    "X-Requested-With": "XMLHttpRequest",
  };
  return config;
});

const axiosFiseApi = axios.create({
  baseURL: process.env.VUE_APP_API_AUTH_URL,
});

axiosFiseApi.interceptors.request.use((config) => {
  const userInfo = store.getters["auth/userInfo"];
  const token = userInfo ? userInfo.token : "";
  config.headers.common = {
    [process.env.VUE_APP_API_TOKEN_HEADER]:
      `${process.env.VUE_APP_API_TOKEN_PREFIX}` + " " + token,
    "X-Requested-With": "XMLHttpRequest",
  };
  return config;
});

export default {
  install(Vue) {
    Vue.prototype.$axiosApiMobile = axiosApiMobile;
    Vue.prototype.$axiosFiseApi = axiosFiseApi;
  },
};

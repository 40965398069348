import errorHandlerMixin from "@/mixins/errorHandlerMixin";

const visitors = {
  namespaced: true,
  state: {
    tempVisitor: null,
    sync: false,
    visitors: {},
    userKey: null,
    userId: null,
  },
  getters: {
    userKey: (state) => state.userKey,
    visitorsUser: (state) => (term) => {
      if (!state.visitors[state.userKey]) return [];

      if (term?.length) {
        return state.visitors[state.userKey].filter((item) => {
          return term.split(" ").every((v) => {
            return (
              item.fullName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(v) ||
              item.companyName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(v)
            );
          });
        });
      } else {
        return state.visitors[state.userKey];
      }
    },
    visitor: (state) => (index) => {
      return state.visitors[state.userKey][index];
    },
    tempVisitor: (state) => state.tempVisitor,
    sync: (state) => state.sync,
  },
  mutations: {
    initUserVisitors(state) {
      if (!state.visitors[state.userKey]) {
        state.visitors[state.userKey] = [];
      }
    },
    setUserKey(state, userId) {
      state.userId = userId;
      state.userKey = "u" + userId;
    },
    setVisitors(state, visitors) {
      state.visitors[state.userKey] = [...visitors];
    },
    addVisitor(state, visitor) {
      visitor.index = state.visitors[state.userKey].length;
      state.visitors[state.userKey].push(visitor);
    },
    setVisitor(state, visitor) {
      if (visitor.index || visitor.index == 0) {
        state.visitors[state.userKey][visitor.index] = visitor;
      }
    },
    setTempVisitor(state, visitor) {
      state.tempVisitor = visitor;
    },
    setSync(state, sync) {
      state.sync = sync;
    },
  },
  actions: {
    isVisitorDuplicated({ state }, visitor) {
      return state.visitors[state.userKey].filter(
        (item) => item.attendeeUuid == visitor.attendeeUuid
      )[0]
        ? true
        : false;
    },
    addVisitorToStart({ state }, visitor) {
      let item = state.visitors[state.userKey].filter(
        (el) => el.attendeeUuid == visitor.attendeeUuid
      )[0];
      if (item) {
        item.description = visitor.description;
        item.imageUrl = visitor.imageUrl;
        item.imageInBase64 = visitor.imageInBase64;
      } else {
        visitor.index = 0;
        let visitors = [visitor];
        state.visitors[state.userKey].forEach((visitor) => {
          visitors.push(visitor);
          visitor.index += 1;
        });
        state.visitors[state.userKey] = visitors;
      }
    },
    async getVisitors() {
      try {
        let response = await this._vm.$axiosApiMobile.get(`auth/visitors`);
        return response.data;
      } catch (e) {
        errorHandlerMixin.errorHandler(this._vm, e.response?.status);
        return null;
      }
    },
    async saveVisitor({ commit, dispatch, rootGetters }, visitor) {
      let response = { status: false };

      try {
        let res = await this._vm.$axiosApiMobile.post(`auth/visitors`, visitor);

        visitor.id = res.data.visitor.id;
        visitor.imageUrl = res.data.visitor.imageUrl;
        visitor.imageInBase64 = null;
        visitor.sync = true;

        if (visitor.index >= 0) {
          commit("setVisitor", visitor);
        } else {
          dispatch("addVisitorToStart", visitor);
        }
        response.status = true;
      } catch (e) {
        if (!rootGetters["offline/isOnline"] || e.response?.status >= 500) {
          if (visitor.index >= 0 || visitor.id) {
            commit("setVisitor", visitor);
          } else {
            dispatch("addVisitorToStart", visitor);
          }
          response.status = true;
          response.isOffline = true;
        }
      }

      commit("setTempVisitor", null);
      return response;
    },
    async compareVisitors({ state }, data) {
      let visitor,
        visitors = [],
        isEqual;

      //Back vs Front
      data.forEach((item) => {
        item.sync = true;
        visitor = state.visitors[state.userKey].filter(
          (el) => el && el.id == item.id
        )[0];
        if (visitor) {
          isEqual =
            visitor.attendeeUuid == item.attendeeUuid &&
            visitor.fullName == item.fullName &&
            visitor.companyName == item.companyName &&
            visitor.description == item.description &&
            !visitor.imageInBase64;
          if (isEqual) {
            visitors.push(item);
          } else {
            visitor.sync = false;
            visitors.push(visitor);
          }
        } else {
          visitors.push(item);
        }
      });

      //Front vs Back
      state.visitors[state.userKey].forEach((item) => {
        if (item) {
          if (!item.id) {
            visitor = visitors.filter(
              (el) =>
                el &&
                el.attendeeUuid == item.attendeeUuid &&
                el.companyName == item.companyName &&
                el.description == item.description
            )[0];
            if (!visitor) {
              item.sync = false;
              visitors.push(item);
            }
          }
        }
      });

      return visitors;
    },
    async syncVisitor({ rootGetters }, visitor) {
      try {
        let response = await this._vm.$axiosApiMobile.post(
          "auth/visitors",
          visitor
        );
        return response.data.visitor;
      } catch {
        console.info(rootGetters["offline/isOnline"]);
        return null;
      }
    },
    async syncVisitors({ state, commit, dispatch }) {
      state.sync = true;
      let response = {
        status: false,
      };

      commit("initUserVisitors");

      let visitorsBack = await dispatch("getVisitors");

      if (visitorsBack == null) {
        state.sync = false;
        return response;
      }

      let visitors = await dispatch("compareVisitors", visitorsBack);

      try {
        await Promise.all(
          visitors.map(async (item, i) => {
            item.index = i;
            if (!item.sync) {
              let visitor = await dispatch("syncVisitor", item);
              item.sync = true;
              item.id = visitor.id;
              item.imageUrl = visitor.imageUrl;
              item.imageInBase64 = null;
            }
          })
        );
        response.status = true;
        state.sync = false;
        commit("setVisitors", visitors);
      } catch {
        state.sync = false;
      }

      return response;
    },
  },
};

export default visitors;

import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import localForage from "localforage";

const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
});

Vue.use(Vuex);

import auth from "./modules/auth";
import fairs from "./modules/fairs";
import visitors from "./modules/visitors";
import offline from "./modules/offline";
import plans from "./modules/provisionalPlansFair";

export default new Vuex.Store({
  modules: {
    auth,
    visitors,
    fairs,
    offline,
    plans,
  },
  plugins: [vuexLocal.plugin],
});

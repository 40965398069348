import Vue from "vue";
import VueRouter from "vue-router";
import guards from "../guards/index";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/public/fairs/Fairs.vue"),
    meta: { layout: "main-layout" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/public/auth/Login.vue"),
    beforeEnter: guards.guest,
    meta: { layout: "main-layout" },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/public/auth/PasswordReset.vue"),
    beforeEnter: guards.guest,
    meta: { layout: "main-layout" },
  },
  {
    path: "/password-reset-confirm",
    name: "password-reset-confirm",
    component: () =>
      import("@/views/private/password-reset/PasswordResetConfirm.vue"),
    beforeEnter: guards.auth,
  },
  {
    path: "/fairs/:slug/expositors",
    name: "expositors",
    component: () => import("@/views/public/expositors/Expositors.vue"),
    meta: { layout: "main-layout" },
    beforeEnter: guards.online,
  },
  {
    path: "/conferences",
    name: "conferences",
    component: () => import("@/views/public/conferences/Conferences.vue"),
    meta: { layout: "main-layout" },
    beforeEnter: guards.online,
  },
  {
    path: "/fairs/:slug",
    name: "fair",
    component: () => import("@/views/public/app-modules/AppModules.vue"),
    meta: { layout: "main-layout" },
  },
  {
    path: "/fairs/:slug/visitors",
    name: "visitors",
    component: () => import("@/views/private/visitors/Visitors.vue"),
    beforeEnter: guards.auth,
    meta: { layout: "main-layout" },
  },
  {
    path: "/fairs/:slug/visitors/create",
    name: "visitor",
    component: () => import("@/views/private/visitors/CreateVisitor.vue"),
    beforeEnter: guards.auth,
  },
  {
    path: "/fairs/:slug/visitors/:id",
    name: "detail-visitor",
    component: () => import("@/views/private/visitors/DetailVisitor.vue"),
    beforeEnter: guards.auth,
  },
  {
    path: "/fairs/:slug/general-information",
    name: "general-information",
    component: () =>
      import("@/views/public/general-information/GeneralInformation.vue"),
    meta: { layout: "main-layout" },
  },
  {
    path: "/fairs/:slug/academic-event",
    name: "academic-event",
    component: () => import("@/views/public/academic-event/AcademicEvent.vue"),
    meta: { layout: "main-layout" },
    beforeEnter: guards.online,
  },
  {
    path: "/fairs/:slug/general-programming",
    name: "general-programming",
    component: () =>
      import("@/views/public/general-programming/GeneralProgramming.vue"),
    meta: { layout: "main-layout" },
    beforeEnter: guards.online,
  },
  {
    path: "/fairs/:slug/interest-places",
    name: "interest-places",
    component: () =>
      import("@/views/public/interest-places/InterestPlaces.vue"),
    meta: { layout: "main-layout" },
    beforeEnter: guards.online,
  },
  {
    path: "/fairs/:slug/plans-fair",
    name: "plans-fair",
    component: () => import("@/views/public/places/Places.vue"),
    meta: { layout: "main-layout" },
  },
  {
    path: "/fairs/:slug/plans-fair/:planId",
    name: "plan-fair",
    component: () => import("@/views/public/places/DetailPlace.vue"),
    meta: { layout: "main-layout" },
  },
  {
    path: "/fairs/:slug/restaurants",
    name: "restaurants",
    component: () => import("@/views/public/restaurants/Restaurants.vue"),
    meta: { layout: "main-layout" },
  },
  {
    path: "/without-connection",
    name: "offline",
    component: () => import("@/views/pages/Offline.vue"),
    meta: { layout: "main-layout" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  next();
});

export default router;

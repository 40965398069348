<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-main
      :style="{
        'padding-bottom':
          $route.name === 'visitors'
            ? '184px'
            : $route.name === 'detail-visitor'
            ? '140px'
            : '56px',
      }"
    >
      <router-view></router-view>
    </v-main>
    <bottom-nav></bottom-nav>
  </v-app>
</template>

<script>
import NavBar from "@/layouts/components/NavBar.vue";
import BottomNav from "./components/BottomNav";

export default {
  components: {
    BottomNav,
    NavBar,
  },
  name: "MainLayout",
};
</script>
